export default {
  getLocalizations(xmlResponse, elementName) {
    let response = {
      template: '',
      replacements: {},
      versionsByLanguage: {},
      versionsByCountry: {}
    }
    const xmlElem = xmlResponse.documentElement.querySelector(elementName)

    if (!xmlElem) {
      return response
    }

    const localizeAttr = xmlElem.attributes.getNamedItem('localized')
    const localeSpecificAttr =
      xmlElem.attributes.getNamedItem('locale-specific')
    const countrySpecificAttr =
      xmlElem.attributes.getNamedItem('country-specific')
    const isLocalized = localizeAttr && localizeAttr.value === 'true'
    const isLocaleSpecific =
      localeSpecificAttr && localeSpecificAttr.value === 'true'
    const isCountrySpecific =
      countrySpecificAttr && countrySpecificAttr.value === 'true'
    if (isCountrySpecific) {
      const xmlElems = xmlElem.querySelectorAll('*')
      const newVersions = {}
      for (const xmlElemEntry of Object.entries(xmlElems)) {
        const xmlElem = xmlElemEntry[1]
        const localeLang = (
          xmlElem.attributes.getNamedItem('abbr') || {
            value: 'default'
          }
        ).value
        newVersions[localeLang] = xmlElem.textContent
      }
      response.versionsByCountry = newVersions
    } else if (isLocaleSpecific) {
      const locales = xmlElem.querySelectorAll('locale')
      const newVersions = {}
      for (const localeEntry of Object.entries(locales)) {
        const locale = localeEntry[1]
        const localeLang = (locale.attributes.getNamedItem('lang') || {}).value
        newVersions[localeLang] = locale.textContent
      }
      response.versionsByLanguage = newVersions
    } else if (isLocalized) {
      let template = xmlElem.querySelector('template').textContent
      response.template = template
      const replacements = xmlElem.querySelectorAll('replacements string')
      let tmpReplacementsObj = {}
      for (const replacementEntry of Object.entries(replacements)) {
        const replacement = replacementEntry[1]
        const keyAttr = replacement.attributes.getNamedItem('key')
        const key = keyAttr && keyAttr.value
        const translationKey = replacement.textContent
        tmpReplacementsObj[key] = translationKey
      }
      response.replacements = tmpReplacementsObj
    } else {
      let textContent = xmlElem.textContent
      if (textContent) {
        response.template = xmlElem.textContent
      }
    }

    return response
  },
  getTranlationText(localizationData, currentLocale, translationParams) {
    if (
      localizationData.versionsByLanguage &&
      Object.entries(localizationData.versionsByLanguage).length > 0
    ) {
      let currentLanguage = currentLocale.split('_')[0]
      return localizationData.versionsByLanguage[currentLanguage] || ''
    }
    let template = localizationData.template || ''
    if (!localizationData.replacements || !template) {
      return template
    }
    for (const [replaceString, translationKey] of Object.entries(
      localizationData.replacements
    )) {
      let translation = this.$t(translationKey, translationParams)
      template = template.replace(`{${replaceString}}`, translation)
    }
    return template
  },
  getValue(xmlElemName, xmlResponse) {
    const xmlElem = xmlResponse.documentElement.querySelector(xmlElemName)
    if (!xmlElem) {
      return
    }

    return xmlElem.textContent
  },
  getAllValues(xmlElemName, xmlResponse) {
    const xmlElems = xmlResponse.documentElement.querySelectorAll(xmlElemName)
    if (!xmlElems) {
      return
    }

    let values = []

    for (const xmlElemEntry of Object.entries(xmlElems)) {
      const xmlElem = xmlElemEntry[1]
      values.push(xmlElem.textContent)
    }

    return values
  }
}
