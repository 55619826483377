export const namespaced = true

export const state = {
  safeTotalLicenses: null,
  safeAvailableLicenses: null,
  freedomeTotalLicenses: null,
  freedomeAvailableLicenses: null,
  keyTotalLicenses: null,
  keyAvailableLicenses: null
}

export const mutations = {
  SET_SAFE_TOTAL_LICENSES(state, value) {
    state.safeTotalLicenses = value
  },
  SET_SAFE_AVAILABLE_LICENSES(state, value) {
    state.safeAvailableLicenses = value
  },
  SET_FREEDOME_TOTAL_LICENSES(state, value) {
    state.freedomeTotalLicenses = value
  },
  SET_FREEDOME_AVAILABLE_LICENSES(state, value) {
    state.freedomeAvailableLicenses = value
  },
  SET_KEY_TOTAL_LICENSES(state, value) {
    state.keyTotalLicenses = value
  },
  SET_KEY_AVAILABLE_LICENSES(state, value) {
    state.keyAvailableLicenses = value
  }
}

export const getters = {
  isTotalStandaloneInstallable(state) {
    return state.safeTotalLicenses > 0
  },
  isSafeInstalled(state) {
    return state.safeAvailableLicenses < state.safeTotalLicenses
  },
  getSafeLicensesUsed(state) {
    return state.safeTotalLicenses - state.safeAvailableLicenses
  },
  isSafeInstallable(state, getters, rootState, rootGetters) {
    let isSafeSubscription = rootGetters['customer/contract/isSafeSubscription']
    let isTotalSubscription =
      rootGetters['customer/contract/isTotalSubscription']
    let isEppSubscription = rootGetters['customer/contract/isEppSubscription']
    let isEppIdpSubscription =
      rootGetters['customer/contract/isEppIdpSubscription']
    let isEppVpnSubscription =
      rootGetters['customer/contract/isEppVpnSubscription']
    let isTotalStandardSubscription =
      rootGetters['customer/contract/isTotalStandardSubscription']
    let isTotalStandardIdMonitoringSubscription =
      rootGetters['customer/contract/isTotalStandardIdMonitoringSubscription']
    let isTotalStandaloneInstallable = getters['isTotalStandaloneInstallable']
    return (
      isTotalStandaloneInstallable ||
      isSafeSubscription ||
      isTotalSubscription ||
      isEppSubscription ||
      isEppIdpSubscription ||
      isEppVpnSubscription ||
      isTotalStandardSubscription ||
      isTotalStandardIdMonitoringSubscription
    )
  },
  isFreedomeInstalled(state) {
    return state.freedomeAvailableLicenses < state.freedomeTotalLicenses
  },
  getFreedomeLicensesUsed(state) {
    return state.freedomeTotalLicenses - state.freedomeAvailableLicenses
  },
  isFreedomeInstallable(state, getters, rootState, rootGetters) {
    let isTotalSubscription =
      rootGetters['customer/contract/isTotalSubscription']
    let isFreedomeSubscription =
      rootGetters['customer/contract/isFreedomeSubscription']
    let isEppVpnSubscription =
      rootGetters['customer/contract/isEppVpnSubscription']
    let isVpnIdpSubscription =
      rootGetters['customer/contract/isVpnIdpSubscription']
    let isTotalStandardSubscription =
      rootGetters['customer/contract/isTotalStandardSubscription']
    let isTotalStandardIdMonitoringSubscription =
      rootGetters['customer/contract/isTotalStandardIdMonitoringSubscription']
    let isTotalStandaloneInstallable = getters['isTotalStandaloneInstallable']
    return (
      !isTotalStandaloneInstallable &&
      (isTotalSubscription ||
        isFreedomeSubscription ||
        isEppVpnSubscription ||
        isVpnIdpSubscription ||
        isTotalStandardSubscription ||
        isTotalStandardIdMonitoringSubscription)
    )
  },
  isKeyInstalled(state) {
    return state.keyAvailableLicenses < state.keyTotalLicenses
  },
  getKeyLicensesUsed(state) {
    return state.keyTotalLicenses - state.keyAvailableLicenses
  },
  isKeyInstallable(state, getters, rootState, rootGetters) {
    const isTotalSubscriptionNotIncludingIdm =
      rootGetters['customer/contract/isTotalSubscriptionNotIncludingIdm']
    const isIDPSubscription = rootGetters['customer/contract/isIDPSubscription']
    const isEppIdpSubscription =
      rootGetters['customer/contract/isEppIdpSubscription']
    const isVpnIdpSubscription =
      rootGetters['customer/contract/isVpnIdpSubscription']
    const isTotalStandardSubscription =
      rootGetters['customer/contract/isTotalStandardSubscription']
    let isTotalStandaloneInstallable = getters['isTotalStandaloneInstallable']
    return (
      !isTotalStandaloneInstallable &&
      (isTotalSubscriptionNotIncludingIdm ||
        isIDPSubscription ||
        isEppIdpSubscription ||
        isVpnIdpSubscription ||
        isTotalStandardSubscription)
    )
  },
  isIdmInstallable(state, getters, rootState, rootGetters) {
    const isTotalSubscription =
      rootGetters['customer/contract/isTotalSubscription']
    const isIDPSubscription = rootGetters['customer/contract/isIDPSubscription']
    const isEppIdpSubscription =
      rootGetters['customer/contract/isEppIdpSubscription']
    const isVpnIdpSubscription =
      rootGetters['customer/contract/isVpnIdpSubscription']
    const isTotalStandardSubscription =
      rootGetters['customer/contract/isTotalStandardSubscription']
    const isTotalStandardIdMonitoringSubscription =
      rootGetters['customer/contract/isTotalStandardIdMonitoringSubscription']
    return (
      isTotalSubscription ||
      isIDPSubscription ||
      isEppIdpSubscription ||
      isVpnIdpSubscription ||
      isTotalStandardSubscription ||
      isTotalStandardIdMonitoringSubscription
    )
  },
  isMigratedStandaloneIdpVpn(state, getters, rootState, rootGetters) {
    const isIDPSubscription = rootGetters['customer/contract/isIDPSubscription']
    const isFreedomeSubscription =
      rootGetters['customer/contract/isFreedomeSubscription']
    return (
      state.safeTotalLicenses > 0 &&
      state.safeAvailableLicenses > 0 &&
      (state.freedomeTotalLicenses > 0 || state.keyTotalLicenses > 0) &&
      (isIDPSubscription || isFreedomeSubscription)
    )
  }
}
