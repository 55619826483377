import CartService from '@/services/CartService.js'
import SegmentService from '@/services/SegmentService'
import AffiliateService from '@/services/AffiliateService'
import XmlLocalizeService from '@/services/XmlLocalizeService'
import CouponShareService from '@/services/CouponShareService.js'
export const namespaced = true

export const state = {
  customerApiCallsAmount: 0,
  availableLocales: [
    { locale: 'en_US', name: 'English' },
    { locale: 'de_DE', name: 'German' },
    { locale: 'fr_FR', name: 'French' },
    { locale: 'pt_PT', name: 'Portuguese' },
    { locale: 'cs_CZ', name: 'Czech' },
    { locale: 'da_DK', name: 'Danish' },
    { locale: 'fi_FI', name: 'Finnish' },
    { locale: 'el_GR', name: 'Greek' },
    { locale: 'zh_HK', name: 'Chinese' },
    { locale: 'hu_HU', name: 'Hungarian' },
    { locale: 'it_IT', name: 'Italian' },
    { locale: 'ja_JP', name: 'Japanese' },
    { locale: 'nl_NL', name: 'Dutch ' },
    { locale: 'no_NO', name: 'Norwegian ' },
    { locale: 'pl_PL', name: 'Polish' },
    { locale: 'ru_RU', name: 'Russian' },
    { locale: 'es_ES', name: 'Spanish ' },
    { locale: 'sv_SE', name: 'Swedish ' },
    { locale: 'tr_TR', name: 'Turkish' },
    { locale: 'vi_VN', name: 'Vietnamese' }
  ],
  currentLocale: 'en_US',
  currentLocaleName: 'English',
  cart: {
    safe: {},
    idp: {},
    freedome: {},
    total: {},
    upgrade_override: {},
    idp_annual: false,
    freedome_annual: false
  },
  brand: 'fsecure',
  logoPath: '/images/logos/logo-f-secure-black.svg',
  logoWidth: 102,
  logoHeight: 28,
  loadedLanguages: ['en'],
  waitForRedirect: false,
  reminderPopupTeaser: {},
  reminderPopupButtonRenewSafe: {},
  reminderPopupButtonRenewTotal: {},
  isReminderPopupOpen: false,
  isRegistrationSuccessPopupOpen: false,
  couponShareProductNames: {},
  nextUrl: '',
  sensePurchaseUrl: '',
  showIDPWeb: process.env.VUE_APP_SHOW_IDP_WEB_UI === 'true',
  isSafeDefaultUpgradePopupSelection: true,
  isInactivePopupOpen: false
}

export const getters = {
  currentLocaleCountry() {
    return this.currentLocale.split('_')[1]
  },
  currentLocaleLanguage() {
    return this.currentLocale.split('_')[0]
  },
  isCartLoaded(state) {
    return state.cart.type !== undefined
  },
  isRenewCart(state) {
    return state.cart.type === 'renew_now'
  },
  isBuyNowCart(state) {
    return state.cart.type === 'buy_now'
  },
  isBuyMoreCart(state) {
    return state.cart.type === 'buy_more'
  },
  isUpgradeAllowed(state) {
    return (
      (state.cart.safe.url || state.cart.idp.url || state.cart.freedome.url) &&
      (state.cart.total.url || state.cart.upgrade_override.url)
    )
  },
  isSenseCardShown(state, getters, rootState, rootGetters) {
    let SenseOrderStatus = rootState.customer.senseStatus
    let isSubUser = rootGetters['customer/contract/isSubUser']
    let isTotalSubscription =
      rootGetters['customer/contract/isTotalSubscription']
    return (
      ((rootState.customer.isSenseAllowedForPurchase &&
        isTotalSubscription &&
        !rootState.customer.contract.isCancellationSet) ||
        SenseOrderStatus === 'ORDERED' ||
        SenseOrderStatus === 'SHIPPED' ||
        SenseOrderStatus === 'ACTIVATED') &&
      !isSubUser
    )
  },
  isSenseSdkCardShown(state, getters, rootState, rootGetters) {
    let hasSenseSdk = rootGetters['customer/hasSenseSdk']
    return hasSenseSdk
  },
  isPurchaseButtonVisible(state, getters, rootState, rootGetters) {
    let isCancellationSet = rootState.customer.contract.isCancellationSet
    let isAutomaticSubscription =
      rootState.customer.contract.isAutomaticSubscription
    let isSubUser = rootGetters['customer/contract/isSubUser']
    let isPrepaid = rootGetters['customer/contract/isPrepaid']
    let isMaxTotalSubscription =
      rootGetters['customer/contract/isMaxTotalSubscription']
    let isManualSubscription =
      rootGetters['customer/contract/isManualSubscription']
    let daysUntilExpiration = rootState.customer.contract.daysUntilExpiration
    let purchaseUrl = getters['purchaseUrl']
    return (
      !isCancellationSet &&
      !isSubUser &&
      !(
        isMaxTotalSubscription &&
        !(isManualSubscription && daysUntilExpiration <= 45)
      ) &&
      !(isPrepaid && daysUntilExpiration === null && isAutomaticSubscription) &&
      !!purchaseUrl
    )
  },
  purchaseUrl(state, getters, rootState, rootGetters) {
    if (state.cart.direct_to_cart) {
      return state.cart.direct_to_cart
    }

    if (
      rootGetters['customer/contract/isTotalSubscription'] ||
      rootGetters['customer/contract/isTotalStandardSubscription'] ||
      rootGetters['customer/contract/isTotalStandardIdMonitoringSubscription']
    ) {
      return state.cart.total.url
    }

    if (state.cart.idp && state.cart.idp.url) {
      return state.cart.idp.url
    } else if (state.cart.freedome && state.cart.freedome.url) {
      return state.cart.freedome.url
    }
    return state.cart.safe.url
  },
  productNames() {
    return {
      safe: process.env.VUE_APP_SAFE_PRODUCT_NAME,
      safeFull: 'F-Secure ' + process.env.VUE_APP_SAFE_PRODUCT_NAME,
      freedome: process.env.VUE_APP_FREEDOME_PRODUCT_NAME,
      freedomeFull: 'F-Secure ' + process.env.VUE_APP_FREEDOME_PRODUCT_NAME,
      key: process.env.VUE_APP_KEY_PRODUCT_NAME,
      keyFull: 'F-Secure ' + process.env.VUE_APP_KEY_PRODUCT_NAME,
      total: process.env.VUE_APP_TOTAL_PRODUCT_NAME,
      totalFull: 'F-Secure ' + process.env.VUE_APP_TOTAL_PRODUCT_NAME,
      sense: process.env.VUE_APP_SENSE_PRODUCT_NAME,
      senseFull: 'F-Secure ' + process.env.VUE_APP_SENSE_PRODUCT_NAME,
      totalIdm:
        process.env.VUE_APP_SAFE_PRODUCT_NAME +
        ' & VPN & ' +
        process.env.VUE_APP_IDM_PRODUCT_NAME,
      totalIdmFull:
        'F-Secure ' +
        process.env.VUE_APP_SAFE_PRODUCT_NAME +
        ' & VPN & ' +
        process.env.VUE_APP_IDM_PRODUCT_NAME
    }
  },
  unifiedProductNames() {
    return {
      epp: process.env.VUE_APP_EPP_PRODUCT_NAME,
      eppFull: 'F-Secure ' + process.env.VUE_APP_EPP_PRODUCT_NAME,
      idp: process.env.VUE_APP_KEY_PRODUCT_NAME,
      idpFull: 'F-Secure ' + process.env.VUE_APP_KEY_PRODUCT_NAME,
      unifiedIdp: process.env.VUE_APP_IDP_PRODUCT_NAME,
      unifiedIdpFull: 'F-Secure ' + process.env.VUE_APP_IDP_PRODUCT_NAME,
      vpn: process.env.VUE_APP_FREEDOME_PRODUCT_NAME,
      vpnFull: 'F-Secure ' + process.env.VUE_APP_FREEDOME_PRODUCT_NAME,
      unifiedVpn: process.env.VUE_APP_VPN_PRODUCT_NAME,
      unifiedVpnFull: 'F-Secure ' + process.env.VUE_APP_VPN_PRODUCT_NAME,
      eppIdp:
        process.env.VUE_APP_EPP_PRODUCT_NAME +
        ' & ' +
        process.env.VUE_APP_IDP_PRODUCT_NAME,
      eppIdpFull:
        'F-Secure ' +
        process.env.VUE_APP_EPP_PRODUCT_NAME +
        ' & ' +
        process.env.VUE_APP_IDP_PRODUCT_NAME,
      eppVpn:
        process.env.VUE_APP_EPP_PRODUCT_NAME +
        ' & ' +
        process.env.VUE_APP_VPN_PRODUCT_NAME,
      eppVpnFull:
        'F-Secure ' +
        process.env.VUE_APP_EPP_PRODUCT_NAME +
        ' & ' +
        process.env.VUE_APP_VPN_PRODUCT_NAME,
      vpnIdp:
        process.env.VUE_APP_VPN_PRODUCT_NAME +
        ' & ' +
        process.env.VUE_APP_IDP_PRODUCT_NAME,
      vpnIdpFull:
        'F-Secure ' +
        process.env.VUE_APP_VPN_PRODUCT_NAME +
        ' & ' +
        process.env.VUE_APP_IDP_PRODUCT_NAME,
      total: process.env.VUE_APP_TOTAL_STANDARD_PRODUCT_NAME,
      totalFull: 'F-Secure ' + process.env.VUE_APP_TOTAL_STANDARD_PRODUCT_NAME,
      totalIdMonitoring:
        process.env.VUE_APP_EPP_PRODUCT_NAME +
        ' & ' +
        process.env.VUE_APP_VPN_PRODUCT_NAME +
        ' & ' +
        process.env.VUE_APP_IDM_PRODUCT_NAME,
      totalIdMonitoringFull:
        'F-Secure ' +
        process.env.VUE_APP_EPP_PRODUCT_NAME +
        ' & ' +
        process.env.VUE_APP_VPN_PRODUCT_NAME +
        ' & ' +
        process.env.VUE_APP_IDM_PRODUCT_NAME,
      eppScam:
        process.env.VUE_APP_EPP_PRODUCT_NAME +
        ' & ' +
        process.env.VUE_APP_SCAM_PRODUCT_NAME,
      eppIdpScam:
        process.env.VUE_APP_EPP_PRODUCT_NAME +
        ' & ' +
        process.env.VUE_APP_IDP_PRODUCT_NAME +
        ' & ' +
        process.env.VUE_APP_SCAM_PRODUCT_NAME,
      eppVpnScam:
        process.env.VUE_APP_EPP_PRODUCT_NAME +
        ' & ' +
        process.env.VUE_APP_VPN_PRODUCT_NAME +
        ' & ' +
        process.env.VUE_APP_SCAM_PRODUCT_NAME
    }
  }
}

export const mutations = {
  SET_CURRENT_LOCALE(state, value) {
    state.currentLocale = value
  },
  SET_CURRENT_LOCALE_NAME(state, value) {
    state.currentLocaleName = value
  },
  INCREASE_CUSTOMER_API_CALLS_AMOUNT(state) {
    state.customerApiCallsAmount++
  },
  SET_CART(state, cartData) {
    state.cart = cartData
  },
  SET_BRAND(state, value) {
    state.brand = value
  },
  SET_LOGO_PATH(state, value) {
    state.logoPath = value
  },
  SET_LOGO_HEIGHT(state, value) {
    state.logoHeight = value
  },
  SET_LOGO_WIDTH(state, value) {
    state.logoWidth = value
  },
  SET_WAIT_FOR_REDIRECT(state, value) {
    state.waitForRedirect = value
  },
  SET_REMINDER_POPUP_TEASER(state, value) {
    state.reminderPopupTeaser = value
  },
  SET_REMINDER_POPUP_BUTTON_RENEW_SAFE(state, value) {
    state.reminderPopupButtonRenewSafe = value
  },
  SET_REMINDER_POPUP_BUTTON_RENEW_TOTAL(state, value) {
    state.reminderPopupButtonRenewTotal = value
  },
  SET_IS_REMINDER_POPUP_OPEN(state, value) {
    state.isReminderPopupOpen = value
  },
  SET_IS_INACTIVE_POPUP_OPEN(state, value) {
    state.isInactivePopupOpen = value
  },
  SET_IS_REGISTRATION_SUCCESS_POPUP_OPEN(state, value) {
    state.isRegistrationSuccessPopupOpen = value
  },
  ADD_LOADED_LANGUAGE(state, value) {
    state.loadedLanguages.push(value)
  },
  SET_COUPON_SHARE_PRODUCT_NAMES(state, value) {
    state.couponShareProductNames = value
  },
  SET_NEXT_URL(state, value) {
    state.nextUrl = value
  },
  SET_SENSE_PURCHASE_URL(state, value) {
    state.sensePurchaseUrl = value
  },
  SET_IS_SAFE_DEFAULT_UPGRADE_POPUP_SELECTION(state, value) {
    state.isSafeDefaultUpgradePopupSelection = value
  }
}

export const actions = {
  async fetchCart({ commit }, locale = false) {
    const response = await CartService.fetchCartData(locale)
    if (!response) {
      return
    }
    if (!response.safe) {
      response.safe = {}
    }
    if (!response.idp) {
      response.idp = {}
    }
    if (!response.freedome) {
      response.freedome = {}
    }
    if (!response.total) {
      response.total = {}
    }
    if (!response.upgrade_override) {
      response.upgrade_override = {}
    }
    if (!response.current_paid_ar_billing) {
      response.current_paid_ar_billing = {}
    }
    commit('SET_CART', response)
    return response
  },
  async fetchCustomizations({ rootState, commit }) {
    let promotion = (rootState.customer.promotionName || '').toLowerCase()
    let affiliate = (rootState.customer.affiliate || '').toLowerCase()

    Promise.all([
      SegmentService.fetchSegmentData(promotion),
      AffiliateService.fetchAffiliateData(affiliate)
    ]).then((values) => {
      let customizationXml = values[0] ? values[0] : values[1]

      if (customizationXml) {
        let logo = customizationXml.documentElement.querySelector('logo')
        if (logo) {
          commit('SET_LOGO_PATH', logo.textContent)
          commit(
            'SET_LOGO_HEIGHT',
            (customizationXml.documentElement.querySelector('logoHeight') || {})
              .textContent
          )
          commit(
            'SET_LOGO_WIDTH',
            (customizationXml.documentElement.querySelector('logoWidth') || {})
              .textContent
          )
        }

        let marketingPopupTeaser =
          customizationXml.documentElement.querySelector('marketingPopupTeaser')
        if (marketingPopupTeaser) {
          const teaserLocalizedData = XmlLocalizeService.getLocalizations(
            customizationXml,
            'marketingPopupTeaser'
          )
          commit('SET_REMINDER_POPUP_TEASER', teaserLocalizedData)
        }

        let marketingPopupButtonRenewSafe =
          customizationXml.documentElement.querySelector(
            'marketingPopupButtonRenewSafe'
          )
        if (marketingPopupButtonRenewSafe) {
          const ButtonLocalizedDataSafeRenew =
            XmlLocalizeService.getLocalizations(
              customizationXml,
              'marketingPopupButtonRenewSafe'
            )
          commit(
            'SET_REMINDER_POPUP_BUTTON_RENEW_SAFE',
            ButtonLocalizedDataSafeRenew
          )
        }

        let marketingPopupButtonRenewTotal =
          customizationXml.documentElement.querySelector(
            'marketingPopupButtonRenewTotal'
          )
        if (marketingPopupButtonRenewTotal) {
          const ButtonLocalizedDataTotalRenew =
            XmlLocalizeService.getLocalizations(
              customizationXml,
              'marketingPopupButtonRenewTotal'
            )
          commit(
            'SET_REMINDER_POPUP_BUTTON_RENEW_TOTAL',
            ButtonLocalizedDataTotalRenew
          )
        }
      }
    })
  },
  async fetchCouponShareProductNames({ rootGetters, commit }) {
    let couponShareProductNames = {}
    const sharedProductShortNames =
      rootGetters['customer/sharedProductShortNames']
    let promisesList = []
    for (const sharedProductEntry of Object.entries(sharedProductShortNames)) {
      const sharedProduct = sharedProductEntry[1]
      promisesList.push(CouponShareService.fetchViewData(sharedProduct))
    }
    Promise.all(promisesList).then((results) => {
      for (let [index, result] of Object.entries(results)) {
        if (!result) {
          continue
        }
        let tmpKey = sharedProductShortNames[index]
        couponShareProductNames[tmpKey] = XmlLocalizeService.getValue(
          'productName',
          result
        )
      }
      commit('SET_COUPON_SHARE_PRODUCT_NAMES', couponShareProductNames)
    })
  }
}
