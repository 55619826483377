import DataService from './DataService'
export default {
  async fetchCustomerData() {
    return DataService.get(process.env.VUE_APP_API_BASE_URL + '/get_customer')
  },
  async fetchMarketingInfo() {
    return DataService.get(
      process.env.VUE_APP_API_BASE_URL + '/get_marketing_info'
    )
  },
  async fetchAutorenewOptions() {
    return DataService.get(
      process.env.VUE_APP_API_BASE_URL + '/get_autorenew_options'
    )
  },
  async fetchConvertToAnnualOptions() {
    return DataService.get(
      process.env.VUE_APP_API_BASE_URL + '/get_monthly_to_annual_options'
    )
  },
  async postChangeEmail(email, token) {
    return DataService.post(
      process.env.VUE_APP_API_BASE_URL + '/change_email',
      {
        email,
        token
      }
    )
  },
  async postDeleteUser(token) {
    return DataService.post(process.env.VUE_APP_API_BASE_URL + '/delete_user', {
      token
    })
  },
  async postActivateWithoutVoucher(token) {
    return DataService.post(
      process.env.VUE_APP_API_BASE_URL + '/activate_customer_without_voucher',
      { token }
    )
  }
}
