import differenceInDays from 'date-fns/differenceInDays'

export const namespaced = true

export const state = {
  expirationDate: null,
  changePaymentMethodUrl: null,
  couponRenewalAllowed: null,
  inCouponRenewalPeriod: null,
  inCouponWhitelistedSegment: null,
  daysUntilExpiration: null,
  discountAllowed: null,
  isAutomaticSubscription: null,
  isCancellationSet: null,
  isUpgradeAvailable: null,
  isUpgradeToTotalAllowed: null,
  licensePeriod: null,
  licenseSize: null,
  reminderDate: null,
  serviceKey: null,
  status: null,
  type: null,
  isDiscountApplied: null,
  productVariant: null
}

export const mutations = {
  SET_EXPIRATION_DATE(state, expirationDate) {
    state.expirationDate = expirationDate
  },
  SET_CHANGE_PAYMENT_METHOD_URL(state, changePaymentMethodUrl) {
    state.changePaymentMethodUrl = changePaymentMethodUrl
  },
  SET_COUPON_RENEWAL_ALLOWED(state, couponRenewalAllowed) {
    state.couponRenewalAllowed = couponRenewalAllowed
  },
  SET_COUPON_RENEWAL_PERIOD(state, inCouponRenewalPeriod) {
    state.inCouponRenewalPeriod = inCouponRenewalPeriod
  },
  SET_IN_COUPON_WHITELISTED_SEGMENT(state, inCouponWhitelistedSegment) {
    state.inCouponWhitelistedSegment = inCouponWhitelistedSegment
  },
  SET_DAYS_UNTIL_EXPIRATION(state, daysUntilExpiration) {
    state.daysUntilExpiration = daysUntilExpiration
  },
  SET_DISCOUNT_ALLOWED(state, discountAllowed) {
    state.discountAllowed = discountAllowed
  },
  SET_IS_AUTOMATIC_SUBSCRIPTION(state, isAutomaticSubscription) {
    state.isAutomaticSubscription = isAutomaticSubscription
  },
  SET_IS_CANCELLATION_SET(state, isCancellationSet) {
    state.isCancellationSet = isCancellationSet
  },
  SET_IS_UPGRADE_AVAILABLE(state, isUpgradeAvailable) {
    state.isUpgradeAvailable = isUpgradeAvailable
  },
  SET_IS_UPGRADE_TO_TOTAL_ALLOWED(state, isUpgradeToTotalAllowed) {
    state.isUpgradeToTotalAllowed = isUpgradeToTotalAllowed
  },
  SET_LICENSE_SIZE(state, licenseSize) {
    state.licenseSize = licenseSize
  },
  SET_REMINDER_DATE(state, reminderDate) {
    state.reminderDate = reminderDate
  },
  SET_LICENSE_PERIOD(state, licensePeriod) {
    state.licensePeriod = licensePeriod
  },
  SET_SERVICE_KEY(state, serviceKey) {
    state.serviceKey = serviceKey
  },
  SET_STATUS(state, status) {
    state.status = status
  },
  SET_TYPE(state, contractType) {
    state.type = contractType
  },
  SET_IS_DISCOUNT_APPLIED(state, isDiscountApplied) {
    state.isDiscountApplied = isDiscountApplied
  },
  SET_PRODUCT_VARIANT(state, productVariant) {
    state.productVariant = productVariant
  }
}

export const getters = {
  isActive() {
    return state.status === 'active'
  },
  isOnHold() {
    return state.status === 'hold'
  },
  isYearly(state) {
    const months = /P(\d*)M/.exec(state.licensePeriod)
    return months && parseInt(months[1]) >= 12
  },
  isMonthly(state) {
    const days = /P(\d*)D/.exec(state.licensePeriod)
    const months = /P(\d*)M/.exec(state.licensePeriod)
    return (
      (days && parseInt(days[1]) >= 30) || (months && parseInt(months[1]) < 12)
    )
  },
  isTotalSubscription(state) {
    return state.serviceKey === 'TOTAL' || state.serviceKey === 'TOTAL_IDM'
  },
  isTotalSubscriptionNotIncludingIdm(state) {
    return state.serviceKey === 'TOTAL'
  },
  isTotalIDMSubscription(state) {
    return state.serviceKey === 'TOTAL_IDM'
  },
  isSafeSubscription(state) {
    return state.serviceKey === 'SAFE'
  },
  isSenseSubscription(state) {
    return state.serviceKey === 'SENSE'
  },
  isIDPSubscription(state) {
    return state.serviceKey === 'IDP' || state.productVariant === 'total_idp'
  },
  isFreedomeSubscription(state) {
    return (
      state.serviceKey === 'FREEDOME' || state.productVariant === 'total_vpn'
    )
  },
  isEppSubscription(state) {
    return state.productVariant === 'total_epp'
  },
  isEppIdpSubscription(state) {
    return state.productVariant === 'total_epp_idp'
  },
  isEppVpnSubscription(state) {
    return state.productVariant === 'total_epp_vpn'
  },
  isVpnIdpSubscription(state) {
    return state.productVariant === 'total_vpn_idp'
  },
  // total_standard_with_sense is included here because the sense should be treated as additional service
  isTotalStandardSubscription(state) {
    return (
      state.productVariant === 'total_standard' ||
      state.productVariant === 'total_standard_with_sense'
    )
  },
  isTotalStandardIdMonitoringSubscription(state) {
    return state.productVariant === 'total_standard_id_monitoring_only'
  },
  getUnifiedProductName(state, getters, rootState, rootGetters) {
    let unifiedProductNames = rootGetters['ui/unifiedProductNames']
    if (state.productVariant === 'total_epp') {
      return unifiedProductNames.epp
    } else if (state.productVariant === 'total_idp') {
      return unifiedProductNames.idp
    } else if (state.productVariant === 'total_vpn') {
      return unifiedProductNames.vpn
    } else if (state.productVariant === 'total_epp_idp') {
      return unifiedProductNames.eppIdp
    } else if (state.productVariant === 'total_epp_vpn') {
      return unifiedProductNames.eppVpn
    } else if (
      state.productVariant === 'total_standard' ||
      state.productVariant === 'total_standard_with_sense'
    ) {
      return unifiedProductNames.total
    } else if (state.productVariant === 'total_standard_id_monitoring_only') {
      return unifiedProductNames.totalIdMonitoring
    } else if (state.productVariant === 'total_epp_scam') {
      return unifiedProductNames.eppScam
    } else if (state.productVariant === 'total_epp_idp_scam') {
      return unifiedProductNames.eppIdpScam
    } else if (state.productVariant === 'total_epp_vpn_scam') {
      return unifiedProductNames.eppVpnScam
    }
    return ''
  },
  getFullUnifiedProductName(state, getters, rootState, rootGetters) {
    let unifiedProductNames = rootGetters['ui/unifiedProductNames']
    if (state.productVariant === 'total_epp') {
      return unifiedProductNames.eppFull
    } else if (state.productVariant === 'total_idp') {
      return unifiedProductNames.idpFull
    } else if (state.productVariant === 'total_vpn') {
      return unifiedProductNames.vpnFull
    } else if (state.productVariant === 'total_epp_idp') {
      return unifiedProductNames.eppIdpFull
    } else if (state.productVariant === 'total_epp_vpn') {
      return unifiedProductNames.eppVpnFull
    } else if (state.productVariant === 'total_vpn_idp') {
      return unifiedProductNames.vpnIdpFull
    } else if (
      state.productVariant === 'total_standard' ||
      state.productVariant === 'total_standard_with_sense'
    ) {
      return unifiedProductNames.totalFull
    } else if (state.productVariant === 'total_standard_id_monitoring_only') {
      return unifiedProductNames.totalIdMonitoringFull
    } else if (state.productVariant === 'total_epp_scam') {
      return unifiedProductNames.eppScam
    } else if (state.productVariant === 'total_epp_idp_scam') {
      return unifiedProductNames.eppIdpScam
    } else if (state.productVariant === 'total_epp_vpn_scam') {
      return unifiedProductNames.eppVpnScam
    }
    return ''
  },
  isTrial(state) {
    return state.type === 'TRIAL'
  },
  isPrepaid(state) {
    return state.type === 'PREPAID'
  },
  isPaid(state) {
    return state.type === 'PAID'
  },
  isSubUser(state) {
    return typeof state.type === 'undefined' || state.type === null
  },
  isMaxTotalSubscription(getters) {
    return (
      (getters.isTotalSubscription || getters.isTotalStandardSubscription) &&
      getters.isMaxLicensesSubscription
    )
  },
  isMaxLicensesSubscription(state) {
    return state.licenseSize >= 25
  },
  isManualSubscription(state, getters) {
    return !getters.isTrial && !state.isAutomaticSubscription
  },
  isContinuousPrepaid(state, getters) {
    return (
      getters.isPrepaid &&
      state.expirationDate === null &&
      state.isAutomaticSubscription
    )
  },
  hasExpired() {
    return state.daysUntilExpiration < 0 && state.daysUntilExpiration !== null
  },
  remindBeforeDaysAmount(state) {
    return differenceInDays(state.expirationDate, state.reminderDate)
  },
  dateFormatted: () => (rootState, date) => {
    let options = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
    }

    let currentLocale = rootState.ui.currentLocale
      ? rootState.ui.currentLocale
      : 'en_US'

    let currentLocaleAltered = currentLocale.replace('_', '-')

    if (!date) {
      return ''
    }

    return typeof date === 'object'
      ? date.toLocaleDateString(currentLocaleAltered, options)
      : ''
  },
  expirationDateFormatted(state, getters, rootState) {
    return getters.dateFormatted(rootState, state.expirationDate)
  },
  voucherRedemptionDateFormatted(state, getters, rootState) {
    let redemptionDate = new Date(state.expirationDate)
    redemptionDate.setDate(redemptionDate.getDate() - 360)
    return getters.dateFormatted(rootState, redemptionDate)
  },
  inCouponWhitelistedSegment(state) {
    return state.inCouponWhitelistedSegment
  }
}
