import DataService from './DataService'

export default {
  async fetchViewData(viewName) {
    return DataService.get(`/coupon-share/${viewName}.xml`, 'xml')
  },
  async postAddCoupon(sharedProductShortkey, token) {
    return DataService.post(
      process.env.VUE_APP_API_BASE_URL + '/add_shared_product_coupon',
      {
        sharedProductShortkey,
        token
      }
    )
  }
}
