import DataService from './DataService'
export default {
  async fetchCartData(locale) {
    if (locale) {
      return DataService.get(
        process.env.VUE_APP_API_BASE_URL + '/get_cart?locale=' + locale
      )
    }
    return DataService.get(process.env.VUE_APP_API_BASE_URL + '/get_cart')
  },
  isUrlToCart(url) {
    return /(\/portal\/(\w)*\/upgrade)|(\/portal\/[\w]*purchase\/)|(\/cart\/[\w]*)/.exec(
      url
    )
  },
  getDirectCartUrlType(url) {
    const match = /\/portal\/[\w]*purchase\/?([0-9a-zA-Z/]*)/.exec(url)
    let purchasePath = match ? match[1] : ''
    let match2
    let match3
    let match4

    if (!purchasePath) {
      match2 = /\/portal\/([\w]*?)\/upgrade\/?([0-9a-zA-Z/]*)/.exec(url)
      purchasePath = match2 ? match2[2] : ''
    }

    if (purchasePath === 'sense') {
      return 'sense'
    }

    if (purchasePath === 'initial/0/1') {
      return 'total'
    }

    if (purchasePath.match(/^initial/)) {
      return 'initial'
    }

    if (purchasePath === 'manualrenew') {
      return 'renew'
    }

    if (match || match2) {
      return 'total'
    }

    if (!purchasePath) {
      match3 = /\/cart\/?([0-9a-zA-Z\-/]*)/.exec(url)
      purchasePath = match3 ? match3[1] : ''
    }

    if (purchasePath.match('upgrade-to-total')) {
      return 'upgrade-to-total'
    }

    if (!purchasePath) {
      match4 = /\/cart\/[\w]*?/.exec(url)
      purchasePath = match4 ? match4[0] : ''
    }

    if (purchasePath === '/cart/') {
      return 'cart'
    }

    return null
  }
}
