import * as contract from './customer/contract'
import * as licenses from './customer/licenses'
import CustomerService from '@/services/CustomerService'
import BillingService from '@/services/BillingService'

export const namespaced = true

export const modules = {
  contract,
  licenses
}

export const state = {
  additionalServices: [],
  affiliate: null,
  defaultTrialConfig: null,
  billingHistory: [],
  countryCode: null,
  email: null,
  environment: null,
  firstName: null,
  geoRegion: null,
  identifier: null,
  isLoggedIn: false,
  isSenseAllowedForPurchase: null,
  lastName: null,
  paymentMethod: null,
  paymentStatus: null,
  promotionId: null,
  promotionName: null,
  remoteDataLoaded: false,
  safeAvenueIframeUrl: null,
  sharedProducts: {},
  status: null,
  senseStatus: null,
  subCategory: null,
  options: [],
  token: null,
  maintenanceSafeAvenue: null,
  isUnified: null,
  isOneClient: null
}

export const getters = {
  fullName(state) {
    let fullName = state.firstName + ' ' + state.lastName
    return fullName === ' ' ? '' : fullName
  },
  isActive(state) {
    return state.status === 'active'
  },
  isIncomplete(state) {
    return state.status === 'incomplete'
  },
  isSuspended(state) {
    return state.status === 'suspended'
  },
  sharedProductShortNames(state) {
    return Object.keys(state.sharedProducts)
  },
  hasSharedProducts(state, getters) {
    return getters['sharedProductShortNames'].length > 0
  },
  isContractWaitingForPayment() {
    return state.paymentStatus === 'waiting_for_payment'
  },
  isPaymentFailing() {
    return state.paymentStatus === 'update_payment_data'
  },
  defaultTrialConfig() {
    return state.defaultTrialConfig
  },
  isVMPhase2Subscription() {
    return (
      state.affiliate === 'virginmedia' && state.promotionName !== 'Homeworks'
    )
  },
  hasSenseSdk(state) {
    let senseSdkAdditionalServices = state.additionalServices.filter(
      (item) => item.name === 'SenseSDK'
    )
    return senseSdkAdditionalServices.length > 0
  },
  isUnified() {
    return state.isUnified && state.isOneClient
  },
  isNewStandalone() {
    return state.isUnified && !state.isOneClient
  },
  getEmail() {
    return state.email
  }
}

export const mutations = {
  SET_ADDITIONAL_SERVICES(state, additionalServices) {
    state.additionalServices = additionalServices
  },
  SET_AFFILIATE(state, affiliate) {
    state.affiliate = affiliate
  },
  SET_DEFAULT_TRIAL_CONFIG(state, defaultTrialConfig) {
    state.defaultTrialConfig = defaultTrialConfig
  },
  SET_BILLING_HISTORY(state, billingHistory) {
    state.billingHistory = billingHistory
  },
  SET_COUNTRY_CODE(state, countryCode) {
    state.countryCode = countryCode
  },
  SET_EMAIL(state, email) {
    state.email = email
  },
  SET_ENVIRONMENT(state, environment) {
    state.environment = environment
  },
  SET_FIRST_NAME(state, firstName) {
    state.firstName = firstName
  },
  SET_GEO_REGION(state, geoRegion) {
    state.geoRegion = geoRegion
  },
  SET_MAINTENANCE_SAFE_AVENUE(state, maintenanceSafeAvenue) {
    state.maintenanceSafeAvenue = maintenanceSafeAvenue
  },
  SET_IDENTIFIER(state, identifier) {
    state.identifier = identifier
  },
  SET_IS_LOGGED_IN(state, isLoggedIn) {
    state.isLoggedIn = isLoggedIn
  },
  SET_IS_SENSE_ALLOWED_FOR_PURCHASE(state, isSenseAllowedForPurchase) {
    state.isSenseAllowedForPurchase = isSenseAllowedForPurchase
  },
  SET_SENSE_DOWNLOAD_URL(state, senseDownloadUrl) {
    state.senseDownloadUrl = senseDownloadUrl
  },
  SET_LAST_NAME(state, lastName) {
    state.lastName = lastName
  },
  SET_PAYMENT_METHOD(state, paymentMethod) {
    state.paymentMethod = paymentMethod
  },
  SET_PAYMENT_STATUS(state, paymentStatus) {
    state.paymentStatus = paymentStatus
  },
  SET_PROMOTION_ID(state, promotionId) {
    state.promotionId = promotionId
  },
  SET_PROMOTION_NAME(state, promotionName) {
    state.promotionName = promotionName
  },
  SET_PROMOTION_SHOW_NAME(state, promotionShowName) {
    state.promotionShowName = promotionShowName
  },
  SET_REMOTE_DATA_LOADED(state, remoteDataLoaded) {
    state.remoteDataLoaded = remoteDataLoaded
  },
  SET_SAFE_AVENUE_IFRAME_URL(state, safeAvenueIframeUrl) {
    state.safeAvenueIframeUrl = safeAvenueIframeUrl
  },
  SET_SENSE_STATUS(state, senseStatus) {
    state.senseStatus = senseStatus
  },
  SET_SHARED_PRODUCTS(state, sharedProducts) {
    state.sharedProducts = sharedProducts
  },
  SET_STATUS(state, status) {
    state.status = status
  },
  SET_SUB_CATEGORY(state, subCategory) {
    state.subCategory = subCategory
  },
  SET_TOKEN(state, token) {
    state.token = token
  },
  SET_OPTIONS(state, options) {
    state.options = options
  },
  SET_AUTORENEW_OPTIONS(state, options) {
    state.autorenewOptions = options
  },
  SET_CONVERT_TO_ANNUAL_OPTIONS(state, options) {
    state.convertToAnnualOptions = options
  },
  SET_IS_UNIFIED(state, isUnified) {
    state.isUnified = isUnified
  },
  SET_IS_ONE_CLIENT(state, isOneClient) {
    state.isOneClient = isOneClient
  }
}

export const actions = {
  async fetch({ commit }) {
    const newData = await CustomerService.fetchCustomerData()
    if (!newData) {
      commit('SET_IS_LOGGED_IN', false)
      commit('SET_REMOTE_DATA_LOADED', true)
      return newData
    }
    let isLoggedIn = typeof newData.contract !== 'undefined'
    commit('SET_MAINTENANCE_SAFE_AVENUE', newData.maintenanceSafeAvenue)
    commit('SET_IS_LOGGED_IN', isLoggedIn)
    commit('SET_REMOTE_DATA_LOADED', true)
    commit('SET_GEO_REGION', newData.geoRegion)
    if (!isLoggedIn) {
      commit('ui/INCREASE_CUSTOMER_API_CALLS_AMOUNT', null, { root: true })
      return newData
    }
    commit('contract/SET_TYPE', newData.contract.contractType)
    commit(
      'contract/SET_IS_CANCELLATION_SET',
      newData.contract.isCancellationSet
    )
    commit(
      'contract/SET_DAYS_UNTIL_EXPIRATION',
      newData.contract.daysUntilExpiration
    )

    let expirationDate = null
    if (newData.contract.expirationDate !== null) {
      expirationDate = new Date(newData.contract.expirationDate)
    }
    commit('contract/SET_EXPIRATION_DATE', expirationDate)

    let reminderDate = null
    if (newData.contract.reminderDate !== null) {
      reminderDate = new Date(newData.contract.reminderDate)
    }
    commit('contract/SET_REMINDER_DATE', reminderDate)

    commit(
      'contract/SET_IS_AUTOMATIC_SUBSCRIPTION',
      newData.contract.isAutomaticSubscription
    )
    commit(
      'contract/SET_IS_UPGRADE_AVAILABLE',
      newData.contract.upgradeAvailable
    )
    commit(
      'contract/SET_IS_UPGRADE_TO_TOTAL_ALLOWED',
      newData.contract.upgradeTotalAllowed
    )
    commit('contract/SET_LICENSE_SIZE', newData.contract.licenseSize)
    commit('contract/SET_SERVICE_KEY', newData.contract.serviceKey)
    commit('contract/SET_PRODUCT_VARIANT', newData.contract.productVariant)
    commit('contract/SET_STATUS', newData.contract.status)
    commit(
      'contract/SET_CHANGE_PAYMENT_METHOD_URL',
      newData.contract.changePaymentMethodUrl
    )
    commit('contract/SET_DISCOUNT_ALLOWED', newData.contract.discountAllowed)
    commit('contract/SET_LICENSE_PERIOD', newData.contract.licensePeriod)
    commit('contract/SET_IS_DISCOUNT_APPLIED', newData.contract.checkDiscount)
    commit(
      'contract/SET_COUPON_RENEWAL_ALLOWED',
      newData.contract.couponRenewalAllowed
    )
    commit(
      'contract/SET_COUPON_RENEWAL_PERIOD',
      newData.contract.inCouponRenewalPeriod
    )
    commit(
      'contract/SET_IN_COUPON_WHITELISTED_SEGMENT',
      newData.contract.inCouponWhitelistedSegment
    )
    commit('SET_ENVIRONMENT', newData.env)
    commit('SET_COUNTRY_CODE', newData.countryCode)
    commit('SET_EMAIL', newData.email)
    commit('SET_FIRST_NAME', newData.firstName)
    commit('SET_LAST_NAME', newData.lastName)
    commit('SET_SENSE_STATUS', newData.sense.status)
    commit('SET_IDENTIFIER', newData.hash.substring(0, 8))
    commit('SET_STATUS', newData.status)
    commit('SET_PAYMENT_METHOD', newData.paymentMethod)
    commit('SET_PAYMENT_STATUS', newData.paymentStatus)
    commit(
      'SET_IS_SENSE_ALLOWED_FOR_PURCHASE',
      newData.sense.isAllowedForPurchase
    )
    commit('SET_SENSE_DOWNLOAD_URL', newData.sense.senseDownloadUrl)
    commit('SET_PROMOTION_ID', newData.promotionId)
    commit('SET_PROMOTION_NAME', newData.promotionName)
    commit('SET_IS_UNIFIED', newData.isUnified)
    commit('SET_IS_ONE_CLIENT', newData.isOneClient)
    var showName = newData.promotionName
    switch (showName) {
      case 'fsecure':
        showName = 'F-Secure'
        break
      case 'edv':
        showName = 'EDV'
        break
    }
    commit('SET_PROMOTION_SHOW_NAME', showName)
    commit('SET_AFFILIATE', newData.affiliate)
    commit('SET_DEFAULT_TRIAL_CONFIG', newData.defaultTrialConfig)
    commit('SET_SAFE_AVENUE_IFRAME_URL', newData.safeAvenueIframe.url)
    commit('SET_ADDITIONAL_SERVICES', newData.additionalServices)
    commit('SET_OPTIONS', newData.options)
    let sharedProducts = {}
    if (newData.sharedProducts && Array.isArray(newData.sharedProducts)) {
      for (const sharedProductEntry of Object.entries(newData.sharedProducts)) {
        const sharedProduct = sharedProductEntry[1]
        let sharedProductKey = Object.keys(sharedProduct)[0]
        sharedProducts[sharedProductKey] = {
          licenses: sharedProduct[sharedProductKey]
        }
      }
    }
    commit('SET_SHARED_PRODUCTS', sharedProducts)
    commit('SET_SUB_CATEGORY', newData.subCategory1)
    commit('SET_TOKEN', newData.token)
    commit('ui/INCREASE_CUSTOMER_API_CALLS_AMOUNT', null, { root: true })
    if (
      newData.licenses &&
      (newData.licenses.safe ||
        newData.licenses.freedome ||
        newData.licenses.idp)
    ) {
      var safe = newData.licenses.safe
      var safeUsed = newData.licenses.safe - newData.licenses.safeUsed
      var freedome = newData.licenses.freedome
      var freedomeUsed =
        newData.licenses.freedome - newData.licenses.freedomeUsed
      var idp = newData.licenses.idp
      var idpUsed = newData.licenses.idp - newData.licenses.idpUsed
      commit('licenses/SET_SAFE_TOTAL_LICENSES', parseInt(safe))
      commit('licenses/SET_SAFE_AVAILABLE_LICENSES', parseInt(safeUsed))
      commit('licenses/SET_FREEDOME_TOTAL_LICENSES', parseInt(freedome))
      commit('licenses/SET_FREEDOME_AVAILABLE_LICENSES', parseInt(freedomeUsed))
      commit('licenses/SET_KEY_TOTAL_LICENSES', parseInt(idp))
      commit('licenses/SET_KEY_AVAILABLE_LICENSES', parseInt(idpUsed))
    }
    return newData
  },
  async fetchBillingHistory({ commit }) {
    const newData = await BillingService.fetchBillingHistoryData()
    if (!newData) {
      return
    }
    commit('SET_BILLING_HISTORY', newData.data)
    return newData
  },
  async fetchEnableAutoRenewOptions({ commit }) {
    const newData = await CustomerService.fetchAutorenewOptions()
    if (!newData) {
      return
    }
    commit('SET_AUTORENEW_OPTIONS', {
      nextBillingDate: newData.next_billing_date,
      billingCurrency: newData.billing_currency,
      billingPrice: newData.billing_price
    })
    return newData
  },
  async fetchConvertToAnnualOptions({ commit }) {
    const newData = await CustomerService.fetchConvertToAnnualOptions()
    if (!newData) {
      return
    }
    commit('SET_CONVERT_TO_ANNUAL_OPTIONS', {
      nextBillingDate: newData.next_billing_date,
      billingCurrency: newData.billing_currency,
      billingPrice: newData.billing_price
    })
    return newData
  }
}
