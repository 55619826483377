export default {
  async fetchTimeout(url, options, timeout) {
    return Promise.race([
      fetch(url, options),
      new Promise((resolve, reject) => {
        const waitTime = timeout || 7000
        setTimeout(() => {
          reject(new Error('timeout'))
        }, waitTime)
      })
    ])
  },
  async get(url, type) {
    const response = await fetch(url, {
      credentials: 'include'
    }).catch((err) => {
      console.log(err)
    })
    return this.processResponse(response, type)
  },
  async processResponse(response, type) {
    if (!response) {
      return
    }

    if (response.status > 400) {
      throw response.status
    }

    if (type === 'text') {
      return response.text()
    }
    if (type === 'xml') {
      const responseText = await response.text()
      if (responseText && responseText.substring(0, 5) !== '<?xml') {
        return
      }
      const oParser = new DOMParser()
      try {
        return oParser.parseFromString(responseText, 'application/xml')
      } catch (err) {
        console.log(err)
      }
    }
    return response.json()
  },
  async post(url, data, type) {
    var formData = new FormData()
    for (const [key, value] of Object.entries(data)) {
      formData.append(key, value)
    }
    let options = {
      method: 'POST',
      body: formData,
      headers: { 'X-CSRFToken': formData.get('token') },
      credentials: 'include'
    }
    const response = await fetch(url, options).catch((err) => {
      console.log(err)
    })
    return this.processResponse(response, type)
  }
}
