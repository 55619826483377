import Vue from 'vue'
import Vuex from 'vuex'
import * as ui from './modules/ui'
import * as customer from './modules/customer'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    ui,
    customer
  }
})
